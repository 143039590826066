<template>
  <div>
    <v-img @click="$router.push({name: 'Login'})" class="mx-auto mb-15" src="@/assets/img/logos/auth-logo.png"
           :height="$vuetify.breakpoint.smAndUp? '196px': '147px'" contain style="cursor: pointer;"></v-img>
    <p class="auth-title mb-5">Restablecimiento de contraseña</p>
    <v-form class="establecer-password-form" @submit.prevent="establecerPassword" ref="form">
      <div>
        <v-row>
          <v-col cols="12" class="py-0">
            <base-text-field
              v-model="new_password1"
              :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.minLength(8), rules.containNumber, rules.containSymbol, rules.containUpperCase, rules.containLowerCase]"
              :type="showPassword1 ? 'text' : 'password'"
              name="input-10-2"
              label="Nueva contraseña"
              @click:append="showPassword1 = !showPassword1"
              :error="errorMessages != ''"
              :error-messages="errorMessages"
              @focus="errorMessages = ''"
            ></base-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <base-text-field
              v-model="new_password2"
              :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.minLength(8), rules.containNumber, rules.containSymbol, rules.containUpperCase, rules.containLowerCase, rules.equalTo('contraseña', new_password1)]"
              :type="showPassword2 ? 'text' : 'password'"
              name="input-10-2"
              label="Repetir Contraseña"
              @click:append="showPassword2 = !showPassword2"
              @focus="errorMessages = ''"
            ></base-text-field>
          </v-col>
          <v-col cols="12" class="mt-4">
            <base-button class="full-width" type="submit">Continuar</base-button>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <ConfirmModal ref="authConfirm"/>
    <LoadingModal v-model="loading" ref="confirm"></LoadingModal>

  </div>
</template>

<script>
import LoadingModal from '@/components/emergentesGlobales/LoadingModal'
import {
  equalTo,
  minLength,
  required,
  containNumber,
  containSymbol,
  containUpperCase,
  containLowerCase
} from '@/utils/rules'
import axios from 'axios'
import ConfirmModal from '@/components/auth/ConfirmModal'
import BaseTextField from '@/components/auth/BaseTextField'
import BaseButton from '@/components/auth/BaseButton'

export default {
  components: {
    ConfirmModal,
    LoadingModal,
    BaseTextField,
    BaseButton
  },
  data () {
    return {
      new_password1: '',
      new_password2: '',
      showPassword1: false,
      showPassword2: false,
      errorMessages: '',
      loading: false,
      showAlert: false,
      uid: '',
      token: '',
      titleAlert: 'Recuperar contraseña',
      messageAlert: 'La contraseña ha sido restablecida con la nueva contraseña.',
      rules: { required, minLength, equalTo, containNumber, containSymbol, containUpperCase, containLowerCase }
    }
  },
  methods: {
    async establecerPassword () {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          const payload = {
            uid: this.uid,
            token: this.token,
            new_password1: this.new_password1,
            new_password2: this.new_password2
          }
          // console.log(payload)
          await axios.post('api/rest-auth/password/reset/confirm/', payload)
          await this.$refs.authConfirm.open({
            type: 'check',
            message: 'La nueva contraseña ha sido guardada con éxito',
            acceptText: 'Iniciar sesión'
          })
          this.$router.push({ name: 'Login' })
        } catch (e) {
          this.errorMessages = e.response.data.new_password2 || ''
          if (this.errorMessages) return
          await this.$refs.authConfirm.open({
            type: 'error',
            title: '¡Ups!',
            message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos',
            acceptText: 'Confirmar'
          })
          this.$router.push({ name: 'Login' })
        } finally {
          this.loading = false
        }
      }
    }
  },
  mounted () {
    this.uid = this.$route.params.uid
    this.token = this.$route.params.token
  }
}
</script>

<style scoped lang="scss">
.establecer-password-form {
  max-width: 448px;
  margin: auto;
}
</style>
