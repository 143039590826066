<template>
  <div class="w-100 h-100 d-flex justify-center align-center">
    <componentSetPassword />
  </div>
</template>

<script>
import componentSetPassword from '@/components/login/SetPassword.vue'

export default {
  name: 'SetPassword',
  components: {
    componentSetPassword
  }
}
</script>
